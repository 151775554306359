import React, { Component } from 'react';
import styles from './CareerAsLICAgentPage.module.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { CONTACT_US_LINK } from '../../Config/Links';

class CareerAsLICAgentPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={styles.CareerAsLICAgentPageWrapper}>
                <Header />
                <div className={styles.CareerAsLICAgentPageBodyWrapper}>
                    <div className={styles.PageContent}>
                        <div className={styles.TopicWrapper}>
                            <div className={styles.HeaderText}>
                                Job Profile
                            </div>
                            <div className={styles.TopicContent}>
                                <ul>
                                    <li>
                                        <span className={styles.TopicName}>Prospecting</span> :
                                        Identifying the prospective customers and fixing appointment.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Need Analysis</span> :
                                        Identifying the needs and getting the prospect agree that need
                                        exists. (i.e. Family Income Provision, Children Education and
                                        Marriage Provision or Retirement Income Provision.)
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Plan Presentation</span> :
                                        Suggesting suitable plans that fulfill the needs as agreed by the
                                        prospect.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Closing the Sale</span> :
                                        Convincing the prospect that you have suggested the best possible
                                        solution for his needs and ask for the order.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>After Sales Service</span> :
                                        Provide efficient after sales service and claims settlement.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.TopicWrapper}>
                            <div className={styles.HeaderText}>
                                Benefits
                            </div>
                            <div className={styles.TopicContent}>
                                <ul>
                                    <li>
                                        <span className={styles.TopicName}>Attractive Renumeration</span> :
                                        An Agent can earn upto 35% of the premium collected as commission.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Career Growth</span> :
                                        Depending upon the work of an agent, he / she can become a privileged member of different clubs
                                        and can also get promoted to permanent role of Development Officer in the organization.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Rewards</span> :
                                        Moreover Agents are recognized for their extra ordinary performance and are suitably honoured and
                                        adequately compensated with cash awards,gifts and memontos which add to the feather to agency earnings.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>No Investment</span> :
                                        An agent can start their agency with absolutely zero investment.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Unlimited Earning</span> :
                                        For an agent there is no limit of earning. He / She can earn as much as they can by selling plans to their prospects.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Interest Free Advances</span> :
                                        Receive Interest Free Festival Advances, Vehicle Advances, Marriage advances, Office maintenance allowances, Furniture
                                        allowance, Housing Loan Loan @ 5 %. etc.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Flexible Timing</span> :
                                        An Agent can work part time or full-time according to their need and convenience. They have total freedom to set your
                                        own working hours.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Gratuity and Group Term Insurance</span> :
                                        An Agent can avail different benefits like Gratuity, Family Mediclaim, Group Term Insurance, etc.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Social Recognition</span> :
                                        An Agent depending upon his' / her's performance can become a member of different Premier Clubs, and can avail other
                                        different facilites. Thought this they can increase their status in the society and in the Corporation.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.TopicWrapper}>
                            <div className={styles.HeaderText}>
                                Eligibility
                            </div>
                            <div className={styles.TopicContent}>
                                <ul>
                                    <li>
                                        <span className={styles.TopicName}>Age</span> :
                                        Must be 18 years or higher.
                                    </li>
                                    <li>
                                        <span className={styles.TopicName}>Qualification</span> :
                                        Should have passed atleast class X.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.KnowMoreSection}>
                        <div className={styles.KnowMoreSectionText}>To know more about the process and other details</div>
                        <div className={styles.ButtonWrapper}>
                            <div className={styles.ButtonContainer}>
                                <a target='_blank' rel="noopener noreferrer"  href={CONTACT_US_LINK}>
                                    <button className={styles.Button}>
                                        <span className={styles.ButtonText}>Contact Us</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
};

export default CareerAsLICAgentPage;