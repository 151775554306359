import React, { Component } from 'react';
import Image1500x500 from '../../Assets/Images/AboutPage/AboutPage-1500x500.jpg';
// import Image800x300 from '../../Assets/Images/AboutPage/AboutPage-800x300.jpg';
// import Image500x200 from '../../Assets/Images/AboutPage/AboutPage-500x200.jpg';
import styles from './AboutPage.module.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { phoneNumber, emailAddress } from '../../Config/PersonalDetails';

class AboutPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (<div className={styles.AboutPageWrapper}>
            <Header />
            <div className={styles.AboutPageBodyWrapper}>
                <div className={styles.ImageWrapper}>
                    <img src={Image1500x500} alt='JoinLIC' className={styles.Image} />
                </div>
                <div className={styles.AboutLICWrapper}>
                    <div className={styles.AboutLICText}>About LIC</div>
                    <div className={styles.AboutLICDescription}>
                        Life Insurance Corporation of India (abbreviated as LIC) is an Indian state-owned
                        insurance group and investment corporation owned by the Government of India. The Life
                        Insurance Corporation of India was founded in 1956. LIC has its headquarters in Mumbai,
                        the commercial capital of India and currently functions with 8 zonal offices and 113
                        divisional offices.
                    </div>
                    <div className={styles.AboutLICDescription}>
                        As of 2019, Life Insurance Corporation of India had total life fund of ₹28.3 trillion.The total value
                        of sold policies in the year 2018-19 is ₹21.4 million. Life Insurance Corporation of India settled 26
                        million claims in 2018-19. It has 290 million policy holders.
                    </div>
                    <div className={styles.AboutLICDescription}>
                        It is the largest life insurance company in India with over 2000 branches and over 14 lakh agents to
                        solicit life insurance business in the country. LIC provides wide range of life insurance plans from
                        pure term insurance plans to savings and investment products. It has a phenomenal presence in both urban
                        and rural India. The motto of the company is "Yogakshemam Vahamyaham" which means - your welfare is our
                        responsibility.
                    </div>
                    <div className={styles.AboutLICText}>Objective of LIC</div>
                    <div className={styles.AboutLICDescription}>
                        The main objective of Life Insurance Corporation of India is to provide life insurance policies to each and
                        every section of the society and to reach out to the rural areas by providing them ample financial coverage
                        in case of death at a very affordable premium rate.As a pioneer of life insurance in India, the company offers
                        a wide range of comprehensive insurance products to its diverse customer base and maximizes the ability of
                        savings of the insurance holder.
                        </div>
                    <div className={styles.AboutLICDescription}>
                        LIC focuses majorly on safeguarding the interests of the insurance holder
                       and acts as trustees in their individual as well as collective abilities.LIC aims to meet the various insurance
                       requirements of an individual by providing best of their services. The company also encourages the involvement
                       and participation of the employees and LIC agents so they work towards the achievement and welfare of the
                       organization.
                    </div>
                </div>
                <div className={styles.DevelopmentOfficerSection}>
                    <div className={styles.WebsiteMotoText}>
                        Through this website we are trying to reach hardworking and ambitious candidates willing to join LIC as Agent.
                    </div>
                    <div className={styles.MoreDetails}>For more details contact</div>
                    <div className={styles.DevelopmentOfficerName}>Niharika Sharma</div>
                    <div className={styles.DevelopmentOfficerText}>Development Officer</div>
                    <div className={styles.ContactDetails}>Mobile Number - <a className={styles.MobileNumberLink} href={`tel:${phoneNumber}`}>{phoneNumber}</a></div>
                    <div className={styles.ContactDetails}>Email - {emailAddress}</div>
                </div>
            </div>
            {/* <div style={{ height: '1000px' }}></div> */}
            <Footer />
        </div>)
    }
};

export default AboutPage;