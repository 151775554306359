import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavigationDropdown.module.css';

const menuDropdownContent = (props) => {
    return (
        <li className={styles.MenuDropdownContent}>
            {props.openinnewtab ?
                <a href={props.link} target='_blank' rel="noopener noreferrer">
                    {props.children}
                    <span className={styles.NavigationItemBar}></span>
                </a>
                :
                <NavLink
                    exact={props.exact}
                    to={props.link}
                    activeClassName={styles.active}
                    onClick={props.click}
                >{props.children}</NavLink>
            }
        </li>
    );
};


export default menuDropdownContent;