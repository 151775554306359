import React from 'react';

import styles from './Backdrop.module.css';

const backdrop = (props) => {
    // console.log('Test--> ', props.test)
    return (
        props.show ? <div className={styles.Backdrop} onClick={props.clicked}></div> : null
    )
};

export default backdrop;