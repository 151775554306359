import React from 'react';
import styles from './Blinker.module.css';

const Blinker = (props) => {

    return (
        <span>
            <svg height="50" width="50" className={styles.Blinking}>
                <circle className={styles.CircleStyle} fill="red" />
            </svg>
        </span>
    )
};

export default Blinker;