import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './NavigationItem.module.css';

const navigationItem = (props) => {
    return (
        <span className={styles.NavigationItem}>
            {props.openinnewtab ?
                <a href={props.link} target='_blank' rel="noopener noreferrer">
                    {props.children}
                    <span className={styles.NavigationItemBar}></span>
                </a>
                :
                <NavLink
                    exact={props.exact}
                    to={props.link}
                    activeClassName={styles.active}
                    openinnewtab={props.openinnewtab}
                >
                    {props.children}
                    <span className={styles.NavigationItemBar}></span>
                </NavLink>}
        </span>
    );
};

export default navigationItem;