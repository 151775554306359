import React, { Component } from 'react';
import styles from './Header.module.css';
import Logo from '../../Assets/Images/Logo.png';
import NavigationItem from '../NavigationItem/NavigationItem';
import * as  Links from '../../Config/Links';
import { phoneNumber } from '../../Config/PersonalDetails';
import Blinker from '../Blinker/Blinker';
import { NavLink } from 'react-router-dom';
import Backdrop from '../Backdrop/Backdrop';
import MenuDropdownContent from '../NavigationDropdown/NavigationDropdown';

class Header extends Component {

    state = {
        isMenuOpen: false
    }

    // componentWillUpdate() {
    //     window.addEventListener("scroll", this.resizeHeaderOnScroll);
    // }

    // resizeHeaderOnScroll = () => {
    //     const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    //         shrinkOn = 100,
    //         headerEl = document.getElementById('headerBar');
    //     if (distanceY > shrinkOn) {
    //         headerEl.classList.add(styles.ShrinkedHeader);
    //     } else if (headerEl.classList.contains(styles.ShrinkedHeader)) {
    //         headerEl.classList.remove(styles.ShrinkedHeader);
    //     }
    // }

    toggleMenu = () => {
        this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }))
    }
    render() {
        return (
            <header id='headerBar' className={styles.HeaderContainer}>
                <div className={styles.HeaderWrapper}>
                    <div className={styles.MobileNumberArea}>
                        <div className={styles.MobileNumber}>
                            <Blinker />
                            <span className={styles.MobileNumberText}>
                            Call us at <a className={styles.MobileNumberLink} href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                            </span>
                        </div>
                    </div>
                    <div className={styles.LogoWrapper}>
                        <NavLink to={Links.HOME_PAGE_LINK} exact>
                            <img src={Logo} alt='Logo' className={styles.LogoImage} />
                        </NavLink>
                    </div>
                    <div className={styles.NavigationMenuWrapper}>
                        <span className={styles.NavigationMenu}>
                            <NavigationItem link={Links.HOME_PAGE_LINK} exact>Home</NavigationItem>
                            <NavigationItem link={Links.ABOUT_PAGE_LINK}>About</NavigationItem>
                            <NavigationItem link={Links.CAREER_AS_AGENT_LINK}>Career as LIC Agent</NavigationItem>
                            <NavigationItem link={Links.CONTACT_US_LINK} openinnewtab={true}>Contact Us</NavigationItem>
                        </span>
                    </div>
                    <div className={styles.MenuButtonWrapper}>
                        <span className={styles.MenuButton} onClick={this.toggleMenu}>
                            <i className={['fa', 'fa-bars', 'fa-lg'].join(' ')}></i>
                        </span>
                        {this.state.isMenuOpen ?
                            <div className={styles.MenuContainer}>
                                <Backdrop show={this.state.isMenuOpen} clicked={this.toggleMenu} />
                                <div>
                                    <ul className={styles.MenuListContainer}>
                                        <MenuDropdownContent link={Links.HOME_PAGE_LINK} exact click={this.toggleMenu}>Home</MenuDropdownContent>
                                        <MenuDropdownContent link={Links.ABOUT_PAGE_LINK} click={this.toggleMenu}>About</MenuDropdownContent>
                                        <MenuDropdownContent link={Links.CAREER_AS_AGENT_LINK} click={this.toggleMenu}>Career as LIC Agent</MenuDropdownContent>
                                        <MenuDropdownContent link={Links.CONTACT_US_LINK} openinnewtab={true} click={this.toggleMenu}>Contact Us</MenuDropdownContent>
                                    </ul>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className={styles.HeaderBarBit}></div>
                </div>
            </header>
        )
    }
}

export default Header;