import React from 'react';
import { NavLink } from 'react-router-dom';
import * as  Links from '../../Config/Links';
import styles from './Footer.module.css';
import { phoneNumber, organisationName } from '../../Config/PersonalDetails';

const Footer = (props) => {

    return (
        <div className={styles.FooterWrapper}>
            <div className={styles.PhoneNumber}>Call us at <a className={styles.WebLinks} href={`tel:${phoneNumber}`}>{phoneNumber}</a></div>
            <div className={styles.LinksWrapper}>
                <span className={styles.EachLinks}>
                    <NavLink className={styles.WebLinks} to={Links.HOME_PAGE_LINK} exact>Home</NavLink>
                </span>
                <span className={styles.EachLinks}>
                    <NavLink className={styles.WebLinks} to={Links.ABOUT_PAGE_LINK}>About</NavLink>
                </span>
                <span className={styles.EachLinks}>
                    <NavLink className={styles.WebLinks} to={Links.CAREER_AS_AGENT_LINK}>Career as LIC Agent</NavLink>
                </span>
                <span className={styles.EachLinks}>
                    <a target='_blank' rel="noopener noreferrer" className={styles.WebLinks} href={Links.CONTACT_US_LINK}>Contact Us</a>
                </span>
            </div>
            <div className={styles.SocialButtonWrapper}>
                <span className={styles.SocialButton}>
                    <a style={{ color: 'white' }} href={'https://www.facebook.com/work4lic'} target='_blank' rel="noopener noreferrer">
                        <i className={["fa", "fa-facebook-official"].join(' ')} aria-hidden="true"></i>
                    </a>
                </span>
                <span className={styles.SocialButton}>
                    <i className={["fa", "fa-linkedin-square"].join(' ')} aria-hidden="true"></i>
                </span>
            </div>
            <div className={styles.DevelopedByWrapper}>
                Developed By -
                <span className={styles.AmitabhTextWrapper}>
                    <a className={styles.AmitabhText} href={'https://amitabh.dev'} target='_blank' rel="noopener noreferrer"> Amitabh</a>
                </span>
            </div>
            {/* <div className={styles.CopyrightWrapper}>
                Copyright &copy; {new Date().getFullYear()} {organisationName}. All rights reserved.
            </div> */}
            <div className={styles.DisclaimerText}>
                This website is owned and maintained by Niharika Sharma, Development Officer (LICI). To know more about LICI {' '}
                <span className={styles.ClickHereText}>
                    <a style={{ color: 'white' }} href={'https://www.licindia.in/'} target='_blank' rel="noopener noreferrer">
                        Click Here
                    </a>
                </span>.
            </div>
            <div className={styles.TermsPrivacyWrapper}>
                <span>
                    <a className={styles.Links} href={'https://work4lic.com/TermsAndConditions.pdf'} target='_blank' rel="noopener noreferrer">
                        Terms and Conditions
                    </a>
                </span>
                <span>{' '}|{' '}</span>
                <span>
                    <a className={styles.Links} href={'https://work4lic.com/PrivacyPolicy.pdf'} target='_blank' rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </span>
            </div>
        </div>
    )
};

export default Footer;