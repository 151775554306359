import React, { Component } from 'react';
import Image1500x500 from '../../Assets/Images/HomePage/HomePage-1500x500.jpg';
// import Image800x300 from '../../Assets/Images/HomePage/HomePage-800x300.jpg';
// import Image500x200 from '../../Assets/Images/HomePage/HomePage-500x200.jpg';
import Bonus from '../../Assets/Images/Vectors/Bonus.png';
import FlexibleTiming from '../../Assets/Images/Vectors/FlexibleTiming.png';
import Growth from '../../Assets/Images/Vectors/Growth.png';
import Pension from '../../Assets/Images/Vectors/Pension.png';
import Promotion from '../../Assets/Images/Vectors/Promotion.png';
import Rewarding from '../../Assets/Images/Vectors/Rewarding.png';
import UnlimitedEarning from '../../Assets/Images/Vectors/UnlimitedEarning.png';
import ZeroInvestment from '../../Assets/Images/Vectors/ZeroInvestment.png';
import SocialRecognition from '../../Assets/Images/Vectors/SocialRecognition.png';
import styles from './HomePage.module.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { CONTACT_US_LINK } from '../../Config/Links';

class HomePage extends Component {
    state = {};

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={styles.HomePageWrapper}>
                <Header />
                <div className={styles.HomePageBodyWrapper}>
                    <div className={styles.ImageWrapper}>
                        <img src={Image1500x500} alt='JoinLIC' className={styles.Image} />
                    </div>
                    <div className={styles.BenefitHeader}>
                        Benefits of becoming an agent
                    </div>
                    <div className={styles.BenefitContainer}>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={Growth} alt='Growth' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Attractive Renumeration</div>
                            <div className={styles.EachBenefitDescription}>
                                {/* An Agent can earn upto 35% of the premium collected as commission. */}
                            </div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={Promotion} alt='Promotion' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Career Growth</div>
                            <div className={styles.EachBenefitDescription}>
                                {/* An agent can grow */}
                            </div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={Rewarding} alt='Rewarding' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Rewards</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={ZeroInvestment} alt='ZeroInvestment' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>No Investment</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={UnlimitedEarning} alt='UnlimitedEarning' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Unlimited Earning</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={Bonus} alt='Bonus' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Interest Free Advances</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={FlexibleTiming} alt='FlexibleTiming' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Flexible Work Timing</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={Pension} alt='Pension' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Gratuity and Group Term Insurance</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                        <div className={styles.EachBenefitContainer}>
                            <div className={styles.EachBenefitImageContainer}>
                                <img src={SocialRecognition} alt='SocialRecognition' className={styles.EachBenefitImage} />
                            </div>
                            <div className={styles.EachBenefitHeader}>Social Recognition</div>
                            <div className={styles.EachBenefitDescription}></div>
                        </div>
                    </div>
                    <div className={styles.KnowMoreSection}>
                        <div className={styles.KnowMoreSectionText}>To know more about the process and other details</div>
                        <div className={styles.ButtonWrapper}>
                            <div className={styles.ButtonContainer}>
                                <a target='_blank' rel="noopener noreferrer"  href={CONTACT_US_LINK}>
                                    <button className={styles.Button}>
                                        <span className={styles.ButtonText}>Contact Us</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{ height: '1000px' }}></div> */}
                <Footer />
            </div>
        )
    }
};

export default HomePage;