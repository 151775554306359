import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, withRouter } from 'react-router-dom';
import * as Links from './Config/Links';
import HomePage from './Pages/HomePage/HomePage';
import AboutPage from './Pages/AboutPage/AboutPage';
import CareerAsLICAgentPage from './Pages/CareerAsLICAgentPage/CareerAsLICAgentPage';

class App extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      this.googleAnalytics();
    } else {
      console.log(this.props.history.location.pathname + this.props.history.location.search);
      this.unlisten = this.props.history.listen((location, action) => {
        console.log(location.pathname + location.search);
      });
    }
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "7bc00963-3b9d-4264-9dae-40243a8379e1";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  };



  componentWillUnmount() {
    this.unlisten();
  }

  googleAnalytics() {
    ReactGA.initialize('UA-159301263-1');
    ReactGA.pageview(this.props.history.location.pathname + this.props.history.location.search);
    this.unlisten = this.props.history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }

  render() {
    return (
      <div>
        <React.Suspense > {/* fallback={loader()} */}
          <Switch>
            <Route path={Links.HOME_PAGE_LINK} exact component={HomePage} />
            <Route path={Links.ABOUT_PAGE_LINK} component={AboutPage} />
            <Route path={Links.CAREER_AS_AGENT_LINK} component={CareerAsLICAgentPage} />
          </Switch>
        </React.Suspense>
      </div>
    );
  }
}

export default withRouter(App);
